import React from 'react';
import ChatConversation from '../pages/ChatComponent';

const ChatbotComponent = ({ chatbot }) => {
  return (
    <div>
      <ChatConversation chatbotId={chatbot.id} />
    </div>
  );
};

export default ChatbotComponent;
