import React, { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import ChatIcon from "@mui/icons-material/Chat";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import { useWebSocket } from "../WebSocketContext";
import { useSelector } from "react-redux";
import { CHAT_url } from "../GlobalVariables";

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: "#171717",
  display: "flex",
  flexDirection: "row",
  overflow: "hidden",
  height: "100%", // S'adapte à l'espace donné
  width: "100%",
}));

const SidebarContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})(({ theme, isOpen }) => ({
  width: isOpen ? "20%" : "5%",
  minWidth: isOpen ? "250px" : "50px",
  backgroundColor: "#0D0D0D",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "auto",
  transition: "width 0.3s ease",
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: "hidden",
  backgroundColor: "#171717",
  display: "flex",
  flexDirection: "column",
}));

const NewChatButton = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginTop: theme.spacing(2),
  padding: theme.spacing(1),
  cursor: "pointer",
  color: "white",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    borderRadius: "4px",
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  width: "100%",
  overflowY: "auto",
  color: "white",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "darkgrey",
    "&:hover": {
      backgroundColor: "grey",
    },
  },
}));

const StyledListItem = styled(ListItem)(({ theme, isActive }) => ({
  marginTop: theme.spacing(1),
  borderRadius: "5px",
  backgroundColor: isActive ? "rgba(255, 255, 255, 0.08)" : "transparent",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: "white",
  },
}));

const ActionIconButton = styled(IconButton)(({ theme, deleteBtn }) => ({
  color: "white",
  "&:hover": {
    color: deleteBtn ? "red" : "green",
  },
}));

const ChatPage = () => {
  const base_url = `${CHAT_url}/chat/title`;
  const socket = useWebSocket();
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { id: activeChatId } = useParams();
  const [chats, setChats] = useState([]);
  const [editingChatId, setEditingChatId] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const fetchChats = async () => {
    try {
      const response = await axios.get(base_url + "?id_user=" + user.user._id);
      if (response.status === 200) {
        const data = response.data;
        const titles = data.titles;

        const newChats = Object.keys(titles).map((key) => ({
          id: titles[key][0],
          title: titles[key][1],
        }));
        setChats(newChats);
        if (newChats.length > 0 && !activeChatId) {
          navigate(`/Chatbot/${newChats[0].id}`);
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des chats", error);
    }
  };

  useEffect(() => {
    fetchChats();
  }, []);

  const handleChatClick = (chatId) => {
    if (socket.current) {
      socket.current.emit("listener", { message: "stop" });
    }
    navigate(`/Chatbot/${chatId}`);
  };

  const saveTitle = async (chatId) => {
    try {
      await axios.put(
        base_url + "?id_user=" + user.user._id + "&id_conv=" + chatId,
        { title: editedTitle },
        { headers: { "Content-Type": "application/json" } }
      );
      setEditingChatId(null);
      setEditedTitle("");
      fetchChats();
    } catch (error) {
      console.error("Erreur lors de la mise à jour du titre", error);
    }
  };

  const handleDeleteClick = async (chatId) => {
    try {
      await axios.delete(CHAT_url + "/chat?id_user=" + user.user._id, {
        data: { ids_conv: [chatId] },
      });
      fetchChats();
    } catch (error) {
      console.error("Erreur lors de la suppression du chat", error);
    }
  };

  return (
    <Container>
      {/* Sidebar */}
      <SidebarContainer isOpen={isSidebarOpen}>
        <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <MenuIcon />
        </IconButton>
        {isSidebarOpen && (
          <>
            <NewChatButton onClick={() => handleChatClick("0")}>
              <ChatIcon sx={{ fontSize: 30 }} />
              <Typography variant="body1">Nouveau chat</Typography>
            </NewChatButton>
            <StyledList>
              {chats.map((chat) => (
                <StyledListItem
                  key={chat.id}
                  isActive={chat.id === activeChatId}
                  secondaryAction={
                    <>
                      <ActionIconButton onClick={() => setEditingChatId(chat.id)}>
                        <EditIcon />
                      </ActionIconButton>
                      <ActionIconButton
                        deleteBtn
                        onClick={() => handleDeleteClick(chat.id)}
                      >
                        <DeleteIcon />
                      </ActionIconButton>
                    </>
                  }
                >
                  {editingChatId === chat.id ? (
                    <StyledTextField
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                      onBlur={() => saveTitle(chat.id)}
                      onKeyPress={(e) =>
                        e.key === "Enter" && saveTitle(chat.id)
                      }
                      autoFocus
                    />
                  ) : (
                    <ListItemText
                      primary={chat.title}
                      onClick={() => handleChatClick(chat.id)}
                    />
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          </>
        )}
      </SidebarContainer>

      {/* Main content */}
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </Container>
  );
};

export default ChatPage;
